.right-header {
  position: absolute;
  z-index: 1;
  right: 1;
  text-align: right;
  top: 75px;
  z-index: 5;
  right: 50px;
  display: flex;
  color: white;
  display: flex;
  align-items: center;
  h5 {
    font-size: 12px;
  }

  .live.rounded-pill {
    background-color: $te-pink;
    height: 24px;
  }

  p {
    margin: unset;
    padding: unset;
  }

  .live {
    display: -webkit-inline-box;
    min-width: 60px;
    text-align: center;
    padding-left: 10px;
  }

  .btn-right-menu {
    border: unset;

    img {
      width: 15px;
      margin-top: -5px;
    }
  }
}
