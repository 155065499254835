
.EZDrawer__container.bla.bla.bla {
  width: 20vw !important;
  background-color: #0b1736d7;
}

.sidebar-right {
  position: absolute;
  z-index: 30;
  right: 0;
  color: white;
  height: 100vh;
  opacity: 0.9;
  padding-top: 60px;
  padding-left: 20px;
  text-align: center;
  width: 100%;

  h5 {
    text-align: center;
    border-bottom: 1px solid;
    margin-bottom: 2rem;
    width: 100%;
  }

  button.thumbnail {
    background-color: #fff;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    margin-bottom: 15px;

    img {
      width: 20px;
    }
  }

  tr { }

  button.new-thumbnail {
    background-color: transparent;
    width: 70px;
    height: 70px;
    border-radius: 15px;
    margin-bottom: 15px;
    border-style: dashed;
    border-width: 2px;
    border-color: silver;

    img {
      width: 10px;
    }
  }

  .table-container {
    width: 300px;
    text-align: center;

    table {
      width: 100%;

      td.description {
        text-align: left;

        .description {
          font-size: 12px;
          vertical-align: text-top;
          margin-top: -15px;
          margin-left: 10px;
        }
      }

      td.check {
        input {
          margin-top: -6px;
          cursor: pointer;
        }
        input::before {
          background-color: red !important;
        }
      }
    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
