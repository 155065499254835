// .mapboxgl-popup {
//   position: fixed;
//   z-index: 1000;
// }
.popup-wrapper {
  .mapboxgl-popup-content {
    width: 22rem !important;
  }

 

  .popup-context-menu-render-popup {
    text-align: left;
    border-radius: 4px;
    width: 160px !important;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 100%;
    overflow: hidden;
    background-color: white;
    ul {
      list-style: none;
      margin-left: "-1rem";
      margin-bottom: 0;
      padding: 6px;
    }
    .context-container-render-popup {
      margin-left: "-1rem";
    }
    .popup-context-item-render-popup {
      text-decoration: none !important;

      padding: 4px;
    }
    .popup-hover-render-popup {
      &:hover {
        background-color: rgba(43, 10, 230, 0.261);
      }
    }

    .results-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
