.nav-bar {
  background-color: $te-medium;
  z-index: 200;
  height: 40px;
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.616);
    border: 1px solid rgba(255, 255, 255, 0.616);
    width: 280px;
    border-radius: 4px;
  }

  svg {
    margin-left: 2px;
  }

  .logo-wrapper {
    margin-left: 10px;
    input {
      margin-top: 5px;
      background-color: $te-medium;
      color: white;
      font-size: 14px;
      outline: none;
      border: none;
      margin: 0;
      height: 30px;
      padding-right: 44px;
      &:focus {
        background-color: white;
        color: black;
      }
    }
  }

  .autocomplete-menu {
    position: absolute;
    width: 300px;
    top: 110%;
    list-style: none;
    z-index: 5;
    background-color: white;
    box-shadow: rgba(36, 36, 49, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-top: 20px;
    .autocomplete-item {
      margin-left: -20px;
      border-bottom: 0.5px solid #00000014;
      margin-right: 20px;
      &:hover {
        background: rgba(41, 40, 68, 0.1);
        cursor: pointer;
      }
    }
  }

  .trueQI-wrapper {
    position: absolute;
    right: 1%;
    top: 25%;
    .description-navbar {
      margin-left: 2px;
      font-weight: 400;
      color: white;
      img {
        margin-bottom: 5px;
      }
    }
  }
}
