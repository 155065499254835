body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $te-dark;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
    font-family: Helvetica, Arial, sans-serif;
}


