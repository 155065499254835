.cluster-list-marker {
  color: black;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0d6efd;
  width: 40px;
}

.list-marker {
  background: none;
  border: none;
}

img {
  padding: 0;
}

.list-marker img {
  width: 25px;
}

.popup-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  h3 {
    font-size: 16px;
    color: #4fc2d9;
  }
  h4 {
    font-size: 12px;
  }
}

.sw {
  h3 {
    font-size: 12px;
    color: white;
  }
}

.mapboxgl-popup-content {
  width: 100% !important;
  color: white;
  margin-top: -20%;
}

.mapboxgl-popup-content {
  background-color: $te-light !important;
}

.mapboxgl-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #d5d3d3;
  color: gray !important;
}

.mapboxgl-marker {
  position: absolute;
  z-index: 1;
}

.surface-water {
  color: black;
  background: rgba(255, 255, 255, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #0692ae;
}

.mapboxgl-popup {
  position: relative;
  z-index: 10 !important;
}
