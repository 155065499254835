.report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $te-dark;
  section {
    position: relative;
    margin-bottom: 2rem;
  }
}

.consumer-report-heading {
  margin-top: 1rem;
  width: 85vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  h3 {
    color: white;
  }
  .breadcrumb-subtitle,
  .breadcrumb-subtitle--parent-section {
    color: white;
  }
}

.report-map-container {
  overflow: hidden;
  position: relative;
  .status-box {
    position: absolute;
    top: 2rem;
    z-index: 100;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    left: 2rem;
  }
}

.zip-menu-header {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.report-map-container,
.ccr-graph-container {
  margin-top: 1rem;
  width: 90vw !important;
  height: 50vh;
  padding: 4px;
  border-radius: 4px;
  background-color: #0b1736;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  canvas {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}

.ccr-graph-container::-webkit-scrollbar {
  width: 1em;
}

.ccr-graph-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ccr-graph-container::-webkit-scrollbar-thumb {
  background-color: #040e24;
  border-radius: 2px;
}

.section-2 {
  color: white;
  background-color: #0b1736;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: row;
  width: 90vw !important;
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .paginate-btn,
  .report-id {
    cursor: pointer;
    padding: 4px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .active {
    background-color: white;
    color: #0b1736;
    font-weight: bold;
    border-radius: 4px;
  }

  .report-id {
    &:hover {
      background-color: white;
      color: #0b1736;
      font-weight: bold;
      border-radius: 4px;
    }
  }
}

.ccr-graph-container {
  height: 50vh;
  background-color: #0b1736;
  position: relative;
  overflow: scroll;
  h1 {
    color: white;
  }
}

.report-table {
  position: absolute;
  top: 8rem;
  left: 0 !important;
  .table-head {
    position: sticky;
    top: -25px;
    z-index: 201;
  }
  .table-body {
    .table-row {
      border-bottom: black;
    }
  }
}

.column-head {
  color: white;
  background-color: #0b1736;
}

.header-item:nth-child(14),
.header-item:nth-child(15) {
  text-decoration: underline;
}

.table-button {
  position: absolute;
  color: white;
  font-weight: bold;
  background-color: #183378;
  border-radius: 4px;
  z-index: 202;
  right: 2%;
}

.header-item,
.table-row,
.table-data {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.chemical {
  position: relative;
  text-decoration: underline;
}

.cas-info {
  color: white;
  background-color: #0b1736;
  position: absolute;
  z-index: 200;
  width: 500px;
  top: 0;
  padding: 8px;
  overflow: hidden;
  left: 0;
  border: 1px solid white;
  box-shadow: rgba(255, 255, 255, 0.063) 0px 6px 24px;
  .cas-container {
    strong {
      font-weight: bold;
      margin-right: 4px;
      text-decoration: underline;
    }
  }
}

.range-slider {
  position: absolute;
  z-index: 101;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  overflow: hidden;
  color: white;
  strong {
    margin-left: 6px;
    border: 1px solid white;
    padding: 2px 4px;
    border-radius: 4px;
  }
  .form-range {
    width: 25% !important;
  }
}

.flagged {
  border: 3px solid red !important;
}

.popup-header {
  position: relative;
  text-decoration: underline;
  cursor: pointer;
  .header-tooltip {
    position: absolute;
    top: 40%;
    z-index: 200;
    background-color: #eeeeee;
    color: black;
    font-weight: 100;
    padding: 2px;
    border-radius: 2px;
    width: 8rem;
    text-align: center;
    p {
      margin: 0;
    }
  }
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.fullscreen-btn {
  right: 4.5%;
}

.fullscreen-enabled {
  background-color: $te-dark;
  .fullscreen-btn {
    display: none;
  }
}