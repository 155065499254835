.breadcrumb-container {
    display: flex;
    justify-content: space-between;
    margin-right: 1%;
    color: white;
    position: relative;
    z-index: 200 !important;
    width: 100%;
    background-color: $te-dark;
    .breadcrumb-switch-container {
        display: flex;
        color: white;
        align-items: center;
        .breadcrumb-switch-title {
            margin-left: 0.4rem;
            margin-right: 8px;
        }
    }
}

.spacer {
    font-size: 0.625em;
    line-height: 0.1em;
    width: 10px;
}

.location {
    &:hover { 
        text-decoration: underline; 
        cursor: pointer;
    }
}
