.mapboxgl-map {
  position: fixed;
  overflow: hidden;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

canvas {
  position: absolute;
}

.copyright {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 3;
  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
}

.map-legend {
  position: absolute;
  right: 1%;
  bottom: 5%;
  color: white;
  background: #0b1736c9;
  z-index: 3;
  padding: 1rem;

  svg {
    margin-right: 8px;
  }
}

.legend-container {
  position: absolute;
  overflow-y: clip;
  bottom: 15px;
  z-index: 1;
  right: 50px;
  width: 450px;
  height: auto;
  color: white;
  opacity: 0.9;
  padding: 20px;
  font-weight: bolder;
  overflow-x: hidden;

  // .te-map-legend-accordion-body {
  //   max-height: 250px;
  //   overflow-y: scroll;
  // }

  // .te-map-legend-accordion-body::-webkit-scrollbar {
  //   width: 1em;
  // }

  // .te-map-legend-accordion button {
  //   background-color: #0b1736;
  //   color: white;
  // }

  // .te-map-legend-accordion-header button::after {
  //   border: 0px !important;
  //   filter: invert(50%) !important;
  // }

  .accordion-collapse {
    background-color: #0b1736;
    color: white !important;
  }
}

.accordion-item {
  background: transparent;
  color: white;
  font-weight: bold;
  border: none;
}

.accordion-header {
  button {
    background: transparent !important;
  }
}

.accordion {
  -bs-accordion-transition: none;
  --bs-accordion-border-width: none;
  --bs-accordion-border-radius: none;
  --bs-accordion-inner-border-radius: none;
}

.accordion-button:not(.collapsed) {
  color: none;
}

.accordion-button:focus {
  border-color: none;
}

.accordion-button {
  font-weight: bold;
  color: white !important;
}
