.loading-spinner {
  justify-content: center;
  position: absolute;
  z-index: 5;
  height: 100vh;
  width: 100% !important;
  align-items: center;
  background-color: #00000040;
  top: 0;
}
